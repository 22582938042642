function base64ToFile(base64String: string, fileName: string, mimeType: string = ''): File {
    const base64Data = base64String.replace(/^data:[^;]+;base64,/, '');

    const byteCharacters = atob(base64Data);
    const byteNumbers: number[] = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    return new File([blob], fileName, { type: mimeType });
}

export default base64ToFile;
