import { PostsListView } from "../views/PostsListView";
import { useGlobalState } from "../store";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FullPostComponent } from "../components/FullPostComponent";


const MainPage = () => {
  const [tabs, setTabs] = useGlobalState("tabs");
  let params = useParams();

  const postId = params["*"]



  if(postId) {
    return <FullPostComponent />
  }


  return (
    <div>
      {tabs.map((t: any) => {
        return <PostsListView isMain={t.id === 1} tab={t} key={t.id} />;
      })}
    </div>
  );
};

export default MainPage;
