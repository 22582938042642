export const TelegramIcon = ({className}: any) => {
  return (
    <svg
    className={className}
      width="43"
      height="37"
      viewBox="0 0 43 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43 3.29658C42.7499 4.4305 42.492 5.56331 42.2509 6.69949C40.4151 15.3367 38.5838 23.9739 36.7503 32.6111C36.6157 33.2433 36.5013 33.88 36.3365 34.5033C35.7948 36.5585 34.3022 37.0805 32.5875 35.8205C29.6528 33.6641 26.7202 31.5031 23.7877 29.3432C23.2165 28.9225 22.6793 28.9532 22.1762 29.4354C20.7497 30.8045 19.321 32.1724 17.8991 33.5459C17.4348 33.9937 16.9033 34.258 16.1844 34.2513C16.2248 33.6449 16.2607 33.0498 16.3033 32.4559C16.4894 29.8202 16.6722 27.1833 16.8763 24.5487C16.892 24.3429 17.0266 24.0988 17.1825 23.9582C23.107 18.5945 29.0405 13.241 34.9728 7.88516C35.0244 7.83792 35.095 7.80192 35.1276 7.74455C35.2431 7.53756 35.3451 7.32382 35.4517 7.11234C35.196 7.06172 34.9291 6.94022 34.688 6.97847C34.411 7.02347 34.1407 7.18433 33.8951 7.33957C26.5341 11.963 19.1741 16.5887 11.8209 21.2268C11.4733 21.4462 11.1862 21.4979 10.7847 21.3697C7.73671 20.3955 4.67858 19.4562 1.62942 18.4865C1.22346 18.3571 0.819745 18.1625 0.475467 17.9128C-0.104311 17.4932 -0.169354 16.8914 0.353231 16.4031C0.763674 16.0195 1.2571 15.6753 1.7752 15.4694C4.64381 14.3265 7.53036 13.2286 10.4124 12.1205C20.2743 8.32276 30.1338 4.53062 39.9957 0.742985C40.2794 0.633867 40.5822 0.541622 40.8827 0.513499C41.966 0.412256 42.6422 0.90835 42.917 1.96466C42.9383 2.04453 42.9719 2.12215 43 2.20089V3.2977V3.29658Z"
        fill="black"
      />
    </svg>
  );
};
