import React, { FC, useEffect, useState } from "react";
import DefaultImg from "../../../assets/img/placeholderBlack.png";
import styled from "styled-components";

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
type Props = {
  src?: string;
  alt?: string;
  className?: string;
};

export const Image: FC<Props> = ({ src, alt = "", className = "" }) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  const onError = () => setImgSrc(DefaultImg);

  useEffect(() => {
    setImgSrc(undefined);
    setTimeout(() => setImgSrc(src), 0);
  }, [src]);

  return (
    <StyledImage
      src={imgSrc ? imgSrc : DefaultImg}
      alt={alt}
      onError={onError}
      className={className}
    />
  );
};
