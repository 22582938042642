import styled from "styled-components";
import image from "../assets/images/post.png";
import { Link } from "react-router-dom";
import {getImgUrl} from "../utils/getImgUrl";
import convertUtcToLocal from "../utils/formatDate";

const StyledPost = styled(Link)`
  color: #000;
  position: relative;
  width: 100%;
  height: 562px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 2px solid #c9c9c9;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 154px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
  @media screen and (max-width: 580px) {
    height: auto;
    padding: 0 0 40px;
    &::after {
      display: none;
    }
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  @media screen and (max-width: 580px) {
    position: static;
    width: 100%;
    height: 80vw;
    margin-bottom: 10px;
  }
`;

const StyledTitle = styled.p`
  position: relative;
  font-size: 45px;
  margin-bottom: 10px;
  z-index: 1;
  @media screen and (max-width: 580px) {
    font-size: 28px;
    margin-bottom: 14px;
  }
`;

const StyledDate = styled.p`
  position: relative;
  font-size: 18px;
  font-weight: 700;
  z-index: 1;
  @media screen and (max-width: 580px) {
    font-size: 14px;
  }
`;

interface IProps {
  post: any;
}

export const LargePostComponent = ({ post }: IProps) => {

  return (
    <StyledPost to={`/${post.id}`}>
      <StyledImage
        src={getImgUrl(post.media_link)}
        alt="Post image"
        width={961}
        height={562}
      />
      <StyledTitle>{post.title}</StyledTitle>

      <StyledDate>{post.date && convertUtcToLocal(post?.date)}</StyledDate>
    </StyledPost>
  );
};
