export const WhatsappIcon = ({ className }: any) => {
  return (
    <svg
      className={className}
      width="43"
      height="44"
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_70_482)">
        <path
          d="M20.3853 0.5C21.2797 0.5 22.1752 0.5 23.0695 0.5C23.5806 0.561589 24.0939 0.60862 24.6016 0.687005C29.4634 1.43503 33.6842 3.45289 37.0386 7.10789C42.4832 13.0394 44.2742 19.9765 42.0966 27.7053C38.4485 40.658 23.5918 46.931 11.6647 40.7218C11.4787 40.6255 11.2074 40.593 11.0046 40.6434C8.47505 41.2839 5.94887 41.9401 3.42493 42.6042C2.33555 42.8908 1.2529 43.201 0.166893 43.5C0.110855 43.444 0.054817 43.388 -0.0012207 43.332C0.0469718 43.2201 0.107493 43.1114 0.139994 42.9939C1.07358 39.6009 2.00941 36.209 2.92507 32.8105C2.98223 32.5999 2.9374 32.311 2.83317 32.1162C0.40001 27.6359 -0.363225 22.8768 0.5345 17.8724C2.1977 8.60841 9.6283 2.29615 17.9891 0.837057C18.7837 0.698203 19.5873 0.610859 20.3853 0.5ZM5.16546 38.3344C5.35599 38.2952 5.4647 38.2784 5.57117 38.2515C7.53025 37.7432 9.48933 37.2392 11.4439 36.7163C11.7533 36.6334 11.9875 36.6771 12.2587 36.8395C16.3472 39.2885 20.7271 40.0846 25.3906 39.0455C34.3488 37.0478 40.9198 28.5138 39.0571 18.4379C37.3771 9.34971 28.745 3.03409 19.5772 4.1326C14.2906 4.76641 10.0766 7.27138 7.02815 11.6386C4.47395 15.2992 3.42829 19.383 3.92702 23.8387C4.23747 26.6147 5.21589 29.1488 6.73676 31.4779C6.8959 31.722 6.93849 31.9337 6.85556 32.2136C6.60451 33.0568 6.37587 33.9067 6.14276 34.7555C5.8211 35.9246 5.50393 37.0948 5.16546 38.3333V38.3344Z"
          fill="black"
        />
        <path
          d="M27.2568 31.9426C25.7695 32.002 24.5389 31.441 23.2736 31.0042C19.6648 29.759 16.8786 27.4131 14.4981 24.5083C13.3549 23.1131 12.2095 21.7212 11.4564 20.0583C10.664 18.3092 10.3435 16.5175 11.1695 14.7113C11.5236 13.9364 12.0705 13.2421 12.5794 12.5478C12.8887 12.1268 13.374 11.9331 13.8996 11.914C14.3737 11.8972 14.8489 11.904 15.323 11.9275C15.7433 11.9487 15.9551 12.2634 16.1053 12.6016C16.4437 13.3675 16.762 14.1424 17.087 14.9151C17.3471 15.5332 17.5903 16.158 17.8705 16.7672C18.0643 17.1905 18.0565 17.5824 17.7842 17.9531C17.3874 18.4928 16.9862 19.0281 16.5659 19.5488C16.1266 20.093 16.1053 20.252 16.4684 20.8545C18.1742 23.6875 20.5244 25.7726 23.6009 27.0178C24.261 27.2843 24.484 27.2429 24.9435 26.6852C25.4501 26.0704 25.95 25.4501 26.4431 24.8252C26.7726 24.4075 26.9811 24.2989 27.4921 24.4938C28.1948 24.7614 28.8774 25.0839 29.561 25.3974C30.0295 25.6124 30.4801 25.87 30.9519 26.0783C31.2881 26.2261 31.6501 26.3134 31.9931 26.4478C32.2822 26.5609 32.4246 26.7938 32.4201 27.1051C32.3999 28.5429 32.0962 29.8475 30.8197 30.7411C29.7135 31.5149 28.5333 32.0289 27.259 31.9426H27.2568Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_70_482">
          <rect
            width="43"
            height="43"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
