import styled from "styled-components";
import FooterLogo from "../assets/images/footer-logo.png";
import { TelegramIcon } from "../assets/images/TelegramIcon";
import { WhatsappIcon } from "../assets/images/WhatsappIcon";
import { CONTENT_WIDTH } from "../constants/constants";
import { Link } from "react-router-dom";
import { useGlobalState } from "../store";
import { translations } from "../constants/translations";

const StyledFooter = styled.footer`
  background-color: #000;
  padding: 50px 15px;
  @media screen and (max-width: 700px) {
    padding: 15px 15px 38px;
  }
`;

const StyledContent = styled.div`
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  gap: 82px;
  @media screen and (max-width: 840px) {
    gap: 15px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 700px) {
    gap: 0;
  }
`;

const StyledLogo = styled(Link)`
  max-width: 349px;
  @media screen and (max-width: 700px) {
    img {
      width: 195px;
      object-fit: contain;
    }
  }
`;

const StyledMenu = styled.div`
  display: flex;
  gap: 42px;
  margin-top: 25px;
  @media screen and (max-width: 840px) {
    width: 100%;
    order: 1;
    justify-content: space-between;
  }
  @media screen and (max-width: 700px) {
    gap: 5px;
    margin-top: 40px;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.p`
  color: #fff;
  font-size: 28px;
  margin-bottom: 25px;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 21px;
  color: #fff;
  margin-bottom: 33px;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  gap: 27px;
  margin-inline-start: auto;
  margin-top: 50px;
  @media screen and (max-width: 700px) {
    margin-top: 25px;
  }
`;

const StyledSMIcon = styled(TelegramIcon)`
  path {
    fill: #fff;
  }
  @media screen and (max-width: 700px) {
    height: 29px;
    width: 29px;
  }
`;

export const Footer = () => {
  const [tabs] = useGlobalState("tabs");

  return (
    <StyledFooter>
      <StyledContent>
        <StyledLogo to="/">
          <img src={FooterLogo} alt="Footer logo" />
        </StyledLogo>
        <StyledMenu>
          <StyledColumn>
            {tabs.map((t) => {
              return (
                <StyledLink to={`/tab/${t.id}`} key={t.id}>
                  {translations[t.name]}
                </StyledLink>
              );
            })}
          </StyledColumn>
        </StyledMenu>
        <StyledBox>
          <Link to="/">
            <StyledSMIcon as={WhatsappIcon} />
          </Link>
          <Link to="/">
            <StyledSMIcon />
          </Link>
        </StyledBox>
      </StyledContent>
    </StyledFooter>
  );
};
