import { useGlobalState } from "../store";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { LargePostComponent } from "../components/LargePostComponent";
import { MediumPostComponent } from "../components/MediumPostComponent";
import { SmallPostComponent } from "../components/SmallPostComponent";
import { Loader } from "../UI/Loader";

const StyledWrapper = styled.div`
  display: block;
  margin-bottom: 100px;
`;

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 1000px;
  flex-shrink: 1;
  margin-inline-end: auto;
  padding: 20px;
  margin-bottom: 65px;
  @media screen and (max-width: 600px) {
    padding: 0 43px;
  }
`;

const TabPage = () => {
  let { tabId } = useParams();
  const [tabs, setTabs] = useGlobalState("tabs");
  const [posts, setPosts] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getPosts = async () => {
    setIsFetching(true);
    try {
      const res = await axios.get(`/api/tab_news/${tabId}`, {
        params: {
          limit: 24,
        },
      });
      if (res) {
      setIsFetching(false);
        setPosts(res.data);
      }
    } catch (e) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getPosts();
  }, [tabId]);
  return (
    <StyledWrapper>
      {isFetching && <Loader />}
      <StyledList>
        {posts[0] && <LargePostComponent post={posts[0]} />}
        {posts.slice(1, 10).map((p, i) => {
          return <MediumPostComponent key={p.id} post={p} />;
        })}
        {posts.slice(10).map((p, i) => {
          let index = i;
          const borderEnd = index % 2 !== 1;
          const borderBottom = i + 2 < posts.length;
          return (
            <SmallPostComponent
              key={p.id}
              post={p}
              borderEnd={borderEnd}
              borderBottom={borderBottom}
            />
          );
        })}
      </StyledList>
    </StyledWrapper>
  );
};

export default TabPage;
