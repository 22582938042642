const blobToDataUrl = async (file:Blob):Promise<string>=>{
   return new Promise(res => {
       const fileReader = new FileReader();
       fileReader.onloadend = () => {
           res(fileReader.result as string);
       };
       fileReader.readAsDataURL(file as Blob);
   })
}
export default blobToDataUrl;
