import { Link } from "react-router-dom";
import styled from "styled-components";
import {getImgUrl} from "../utils/getImgUrl";
import convertUtcToLocal from "../utils/formatDate";

const StyledPost = styled(Link)`
  width: 100%;
  height: 332px;
  color: #000;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 2px solid #c9c9c9;
  margin-bottom: 30px;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    height: auto;
    gap: 10px;
    padding-bottom: 40px;
    margin-bottom: 13px;
    border-width: 1px;
  }
`;

const StyledImage = styled.img`
  width: 472px;
  height: 312px;
  flex-shrink: 0;
  object-fit: cover;
  @media screen and (max-width: 580px) {
    width: 100%;
    height: 80vw;
  }
`;

const StyledContent = styled.div`
  flex-grow: 1;
  padding: 15px 28px;
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

const StyledTitle = styled.p`
  font-size: 28px;
  margin-bottom: 10px;
`;

const StyledDate = styled.p`
  font-size: 18px;
  @media screen and (max-width: 800px) {
    font-size: 14px;
    font-weight: 700;
  }
`;

interface IProps {
  post: any;
}

export const MediumPostComponent = ({ post }: IProps) => {
  return (
    <StyledPost to={`/${post.id}`}>
      <StyledContent>
        <StyledTitle>{post.title}</StyledTitle>
        <StyledDate>{post.date && convertUtcToLocal(post?.date)}</StyledDate>
      </StyledContent>
      <StyledImage src={getImgUrl(post.media_link)} alt="Post image" />
    </StyledPost>
  );
};
