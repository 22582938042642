function convertUtcToLocal(dateStr: string): string {
    // Parse the input date string
    const [time, date] = dateStr.split(' ');
    const [hours, minutes] = time.split(':').map(Number);
    const [day, month, year] = date.split('.').map(Number);

    // Create a Date object in UTC
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Convert to local time
    const localDate = new Date(utcDate);

    // Format the local date back to the required format
    const localHours = localDate.getHours().toString().padStart(2, '0');
    const localMinutes = localDate.getMinutes().toString().padStart(2, '0');
    const localDay = localDate.getDate().toString().padStart(2, '0');
    const localMonth = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const localYear = localDate.getFullYear().toString();

    return `${localHours}:${localMinutes} ${localDay}.${localMonth}.${localYear}`;
}

export default convertUtcToLocal;
