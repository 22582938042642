import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import "./assets/sass/style.scss";
import AppRouter from "./AppRouter";
import {PopupHandler} from "./components/PopupHandler";
import {ToastContainer} from "react-toastify";
import {ADMIN_PANEL} from "./config";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {useGlobalState} from "./store";
import {CONTENT_WIDTH} from "./constants/constants";
import styled from "styled-components";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;
  & > div:first-child {
    flex-grow: 1;
  }
  & > div:last-child {
    flex-grow: 0;
    flex-shrink: 0;
  }
  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
`;

const StyledAdblock = styled.span`
  width: 300px;
  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;

// @ts-ignore
window.IS_PUBLIC_SITE = !ADMIN_PANEL;

const App: React.FC = () => {
  const path = useLocation().pathname;
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [tabs, setTabs] = useGlobalState("tabs");

  const checkAdmin = () => {
    const create = path.split("/").some((el) => el === "create");
    setIsCreate(create);
  };

  useEffect(() => {
    checkAdmin();
  }, [path]);

  useEffect(()=>{
    if(ADMIN_PANEL){
      document.dir = "ltr";
    }
  },[ADMIN_PANEL])

  //Set user info
  const authToken = sessionStorage.getItem("token");
  useEffect(() => {
    if (authToken) {
      (async function () {
        const { data } = await axios.get(
          "https://admin.thinktac.io/api/user_info/",
          {
            headers: {
              Authorization: `Token ${authToken}`,
            },
          }
        );
        if (data) {
          sessionStorage.setItem("userInfo", JSON.stringify(data));
        }
      })();
    }
  }, [authToken]);

  useEffect(() => {
    if(ADMIN_PANEL) return;
    axios.get("/api/tabs/").then((res) => {

      setTabs(res.data)
    });
  }, []);

  return (
    <div>
      {!ADMIN_PANEL && <Header />}
      {ADMIN_PANEL && (
        <>
          <PopupHandler />
          <ToastContainer />
        </>
      )}

      <StyledContent id="main">
        <ScrollToTop/>
        <AppRouter />
        {/*{!ADMIN_PANEL && (*/}
        {/*  <StyledAdblock className="ad-block">*/}
        {/*    <Adsense slot="2148763374" width="360px" height="50%" />*/}
        {/*    <Adsense slot="9463166679" width="360px" height="50%" />*/}
        {/*  </StyledAdblock>*/}
        {/*)}*/}
      </StyledContent>

      {!ADMIN_PANEL && <Footer />}
    </div>
  );
};

export default App;
