import 'react-image-crop/src/ReactCrop.scss'

import {Modal} from "./shared/Modal/Modal";
import {useEffect, useRef, useState} from "react";
import {Crop, PixelCrop, ReactCrop} from "react-image-crop";
import useDebounceEffect from "../utils/hooks/useDebounceEffect";
import canvasPreview from "../utils/CanvasPreview";
import styles from "./../assets/sass/blocks/crop-image-modal.module.scss"
import {Button} from "./shared/Button/Button";
import blobToDataUrl from "../utils/blobToDataUrl";
import base64ToFile from "../utils/base64ToFile";
interface IProps{
    isOpened:boolean,
    onClose:()=>void,
    file:File | undefined,
    onSave:(file:File, base64:string)=>void,
}
const CropImageModal = ({isOpened, file, onClose, onSave}:IProps)=>{
    const [imgSrc, setImgSrc] = useState("");
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    useEffect(()=>{
        if(!file) return;
        blobToDataUrl(file).then(setImgSrc)
    },[file])

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                );
            }
        },
        100,
        [completedCrop],
    );

    async function getCropedImage() {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        );

        const blob = await offscreen.convertToBlob({
            type: "image/jpg",
        });
        blobToDataUrl(blob).then(setImgSrc)
        setCrop(undefined);
    }

    const handleReset = ()=>{
        if(!file) return;
        blobToDataUrl(file).then(setImgSrc)
        setCrop(undefined);
    }

    const handleSave = ()=>{
        if(!file?.name || !file.type) return;
        const newFile =  base64ToFile(imgSrc, file?.name, file?.type);
        onSave(newFile, imgSrc)
        onClose();
    }

    return (
        <Modal isOpened={isOpened} title={"Crop Image"} onClose={onClose}>
            <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
            >
                <img
                    className={styles.img}
                    ref={imgRef}
                    src={imgSrc}
                />
            </ReactCrop>
            {crop && (
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop?.width,
                        height: completedCrop?.height,
                    }}
                />
            )}
            <div className={styles.buttons}>
                <Button onClick={getCropedImage}>Crop</Button>
                <Button onClick={handleReset}>Reset</Button>
                <Button onClick = {handleSave}>Save</Button>
            </div>
        </Modal>
    )
}




export default CropImageModal;
