import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {TextArea} from "../../components/TextArea/TextArea";
import PostEditor from "../../components/PostEditor/PostEditor";
import dropIcon from "../../assets/img/dropIcon.png";
import iconAuthor from "../../assets/img/iconAuthor.svg";
import link from "../../assets/img/link.svg";
import copyright from "../../assets/img/copyright.svg";
import {Checkbox, Dropdown} from "../../components";
import {useNavigate} from "react-router";
import {useGlobalState} from "../../store";
import {useHttp} from "../../hooks/useHttp";
import {CreatePost} from "../../types/api/admin";
import {TabType} from "../../types/api/subdomainTacnews";
import useQuery from "../../utils/hooks/useQuery";
import {Button} from "@mui/material";
import {Image} from "../../components/shared/Image/Image";
import {getImgUrl} from "../../utils/getImgUrl";
import Multiselect from "../../components/Multiselect/Multiselect";
import {divideConent} from "../../utils/divideConent";
import CropImageModal from "../../components/CropImageModal";

export const CreatePage: React.FC = () => {
  const history = useNavigate();
  const [cropImageModal, setCropImageModal] = useState(false);
  const [tabs, setTabs] = useGlobalState("tabs");

  const [adminEditNews, setAdminEditNews] = useGlobalState("adminEditNews");


  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [data, setData] = useState<CreatePost>({
    title: "",
    text: "",
    link: "",
    copyright_label: "",
    copyright_link: "",
    by: "",
    tab: 0,
    media: "",
    cupturn: "",
    domains:undefined
  });
  const [drag, setDrag] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState<any>();
  const [domainOptions, setDomainOptions] = useState<{ value: string, name: string }[]>([])
  const [imageToCrop, setImageToCrop] = useState<File | undefined>(undefined);

  const [isCheckedBreaking, setIsCheckedBreaking] = useState<boolean>(false);
  const [isCheckedMain, setIsCheckedMain] = useState<boolean>(false);
  const [isCheckedSecondary, setIsCheckedSecondary] = useState<boolean>(false);

  const { request } = useHttp();
  const query = useQuery();

  const dragStartHandler = (e: any) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (e: any) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDropHandler = (e: any, type?: "file" | "picture") => {
    setDrag(false);
    if (type !== "file") e.preventDefault();
    let files = type === "file" ? [e] : [...e.dataTransfer.files];
    if(!files.length) return;
    if (
        // files[0].name.split(".")[files[0].name.split(".").length - 1] === "png" ||
        // files[0].name.split(".")[files[0].name.split(".").length - 1] === "jpg" ||
        // files[0].name.split(".")[files[0].name.split(".").length - 1] === "jpeg"
        true
    ) {
      const file = files[0];
      if(drag && file.type.includes("image")){
        setImageToCrop(file);
        setCropImageModal(true)
        return;
      }
      setSelectedFile(files[0]);
    } else {
      toast.error("Only PNG, JPG are supported");
    }

    setDrag(false);
  };

  useEffect(() => {
    (async function () {
      const respTabs: Array<TabType> | null = await request({
        path: "/tabs/",
        method: "GET",
      });
      if (respTabs) {
        const newTabs = respTabs.map((item) => ({
          id: item.id,
          tab: item.name,
          has: true,
          domain: item.domain
        }));

        setTabs(newTabs);
      }
    })();

    if (query.get("edit") === "true" && adminEditNews) {
      const data: any = {
        title: adminEditNews.title,
        text: adminEditNews.text,
        copyright_label: adminEditNews.copyright_label,
        copyright_link: adminEditNews.copyright_link,
        link: adminEditNews.link,
        by: adminEditNews.by,
        tab: adminEditNews.tab,
        media: getImgUrl(adminEditNews.media_link),
        cupturn: adminEditNews.cupturn,
        original_id: adminEditNews.original.id,
      }
      if (adminEditNews.domain) {
        data.domains = [adminEditNews.domain.toString()]
      }
      setData(data);
      setPreview(getImgUrl(adminEditNews.media_link));
      setIsCheckedBreaking(adminEditNews.breacking);
      setIsCheckedMain(adminEditNews.main);
      setIsCheckedSecondary(adminEditNews.secondary_main);
    } else if (query.get("edit") === "true" && !adminEditNews) {
      history({ pathname: "/admin" });
    }
  }, []);


  useEffect(() => {
    if (selectedFile) {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(getImgUrl(adminEditNews?.media_link || ""));
    }
  }, [selectedFile]);

  const handleClick = async (domains: string[] | null, editId?: number) => {
    setIsFetching(true);
    if (data) {
      let requestBody: any = new FormData();
      requestBody.append("title", data.title);
      requestBody.append("copyright_label", data.copyright_label);
      requestBody.append("copyright_link", data.copyright_link);
      requestBody.append("by", data.by);
      requestBody.append("main", isCheckedMain);
      requestBody.append("breacking", isCheckedBreaking);
      requestBody.append("tab", data.tab);
      requestBody.append("link", data.link);
      requestBody.append("media", selectedFile ?? data.media);
      requestBody.append("secondary_main", isCheckedSecondary);
      requestBody.append("cupturn", data.cupturn);
      requestBody.append('published', !!domains)
      if (domains) {
        requestBody.append("domains", domains.join(","))
      }
      //requestBody.append('text', data.text.replaceAll("\n", "<br>"))

      //Upload all data except text
      const resp: any = await request({
        path: `/save${editId ? "/" + editId : ""}/`,
        method: "PUT",
        body: requestBody,
      });


      //Handle error
      if (resp?.error) {
        toast.error(resp?.error ?? "Error!");
        setIsFetching(false);
        return;
      }

      //Handle success
      if (!resp?.error) {
        toast.success("Success!");
        setIsFetching(false);
      }

      //Divide text into partials and upload each partial separately
      const partials = divideConent(data.text);
      let id = resp.id;
      if(resp instanceof Array) id = resp[0].id;

      for (let partial of partials) {
        const partRes: any | null = await request({
          path: `/add_text/${id}/`,
          method: "PUT",
          body: { text: partial },
        });
        if (partRes?.error) {
          setIsFetching(false);
          return;
        }
      }

      // if (!domains) {
      //   toast.success("Success!");
      // }


      // if (domains) {
      //   const formData = new FormData()
      //   formData.append("domains", domains.join(","))

      //   const publishResp: any | null = await request({
      //     path: `/pending/publish/${resp}/`,
      //     method: "POST",
      //     body: formData
      //   });
      //   setIsFetching(false);
      //   if (!publishResp?.error) toast.success("Success!");
      //   if (publishResp?.error) toast.error(publishResp?.error);
      // }

      setIsFetching(false);
      history({ pathname: "/admin" });
    }
  };


  const tabsOptions = useMemo(() => {
    return tabs.filter(t => data.domains?.includes(t.domain.toString())).map(t => ({ name: t.tab, value: t.id.toString() }))
  }, [tabs, data.domains])


  const handleLoadFile =
      (type: "file" | "picture") =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files && event.target.files[0];
            if(!file) return;
            if(file.type.includes("image")){
              setImageToCrop(file);
              setCropImageModal(true)
              return;
            }
            onDropHandler(file, type);
          };

  const handleSaveCroppedImage = (file:File, base64:string)=>{
    onDropHandler(file, "file");
    setPreview(base64);
  }


  const handleFetchDomains = useCallback(async () => {
    const domainsRes = await request({
      path: "/domains/",
      method: "GET",
    })
    const opts = domainsRes?.map((d: any) => ({ value: String(d.id), name: d.domain_regex })) || []
    setDomainOptions(opts)
  }, [])



  useEffect(() => {
    handleFetchDomains()
  }, [handleFetchDomains])


  useEffect(() => {
    if (isCheckedMain && isCheckedSecondary) {
      setIsCheckedSecondary(false)
    }
  }, [isCheckedMain, isCheckedSecondary])



  const handleChangeDomains = useCallback((domains: string[]) => {
    setData((p: any) => ({...p, tab: null, domains }))
  }, [])



  useEffect(() => {
    return () => {
      setAdminEditNews(null)
    }
  }, [])

  return (
      <>
        {cropImageModal && <CropImageModal onSave={handleSaveCroppedImage} file={imageToCrop} isOpened={cropImageModal}  onClose={()=>setCropImageModal(false)}/>}
        {/* <div className="circle firstcircle"></div>
      <div className="circle secondcircle"></div>
      <div className="circle thirdcircle"></div>
      <div className="circlebot fourthcircle"></div> */}


        <div className="create">
          <div className="create__right">
            <TextArea
                placeholder="Title"
                title={true}
                value={data.title}
                onChange={(event: any) =>
                    setData((prev) => ({ ...prev, title: event.target.value }))
                }
            />
            {drag ? (
                <span
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragOver={(e) => dragStartHandler(e)}
                    onDrop={(e) => onDropHandler(e)}
                    className="dropArea"
                >
              Drag and drop files to upload them
            </span>
            ) : (
                <div
                    onDrop={(e) => onDropHandler(e)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragOver={(e) => dragStartHandler(e)}
                    className="dropArea"
                >
                  {preview ? (
                      <div className="create__wrapper__preview">
                        {(!preview || !preview?.startsWith("data:video")) ? (
                            <Image src={preview} alt="preview" className="preview" />
                        ):(
                            <video>
                              <source src = {preview}/>
                            </video>
                        )}
                        <Button
                            className="create__wrapper__preview__upload-button"
                            component="label"
                        >
                          {"Select media"}
                          <input
                              onClick={(event:any)=> {
                                event.target.value = null
                              }}
                              type="file"
                              accept="image/*, video/*"
                              hidden
                              onChange={handleLoadFile("file")}
                          />
                        </Button>
                      </div>
                  ) : data.media ? (
                      <div className="create__wrapper__preview">
                        <Image src={data.media} alt="dropIcon" />
                        <Button
                            className="create__wrapper__preview__upload-button"
                            component="label"
                        >
                          {"Select media"}
                          <input
                              onClick={(event:any)=> {
                                event.target.value = null
                              }}
                              type="file"
                              accept="image/*, video/*"
                              hidden
                              onChange={handleLoadFile("file")}
                          />
                        </Button>
                      </div>
                  ) : (
                      <div className="create__wrapper">
                        <img src={dropIcon} alt="dropIcon" />
                        <Button
                            className="create__wrapper__upload-button"
                            component="label"
                        >
                          {"Select media"}
                          <input
                              onClick={(event:any)=> {
                                event.target.value = null
                              }}
                              type="file"
                              accept="image/jpeg, video/*"
                              hidden
                              onChange={handleLoadFile("file")}
                          />
                        </Button>
                      </div>
                  )}
                </div>
            )}
            <div className="create__right_by">
              <a
                  href={data.link}
                  target="_blank"
                  style={{ display: "flex" }}
                  rel="noreferrer"
              >
                <img src={iconAuthor} alt="iconAuthor" />
              </a>
              <input
                  value={data.by}
                  onChange={(event) =>
                      setData((prev) => ({ ...prev, by: event.target.value }))
                  }
                  type="text"
                  placeholder="By"
              />
            </div>
            <div className="create__right_by">
              <a
                  href={data.link}
                  target="_blank"
                  style={{ display: "flex" }}
                  rel="noreferrer"
              >
                <img src={link} alt="link" />
              </a>
              <input
                  value={data.link}
                  onChange={(event) =>
                      setData((prev) => ({ ...prev, link: event.target.value }))
                  }
                  type="text"
                  placeholder="link"
              />
            </div>
            <div className="create__right_copyright">
              <img src={copyright} alt="copyright" />
              <input
                  value={data.copyright_label}
                  onChange={(event) =>
                      setData((prev) => ({
                        ...prev,
                        copyright_label: event.target.value,
                      }))
                  }
                  type="text"
                  placeholder="copyright"
              />
            </div>

            <PostEditor
                onChange={(content: string) => {
                  setData((prev) => ({ ...prev, text: content }));
                }}
                placeholder="Enter texts..."
                defaultValue={data.text}
            />
          </div>

          <div className="create__left">
            <div className="create__left_check">
              Main item{" "}
              <Checkbox
                  checked={isCheckedMain}
                  disabled={isCheckedSecondary}
                  setIsCheckedCreate={setIsCheckedMain}
              />
            </div>
            <br />
            <div className="create__left_check">
              Secondary item
              <Checkbox
                  checked={isCheckedSecondary}
                  disabled={isCheckedMain}
                  setIsCheckedCreate={setIsCheckedSecondary}
              />
            </div>
            <div className="create__left_check breaking">
              <div className="create__left_check_top">
                Breaking news{" "}
                <Checkbox
                    checked={isCheckedBreaking}
                    setIsCheckedCreate={setIsCheckedBreaking}
                />
              </div>
              <div className="create__left_check_bot">
                <TextArea
                    value={data.cupturn}
                    onChange={(event: any) =>
                        setData((prev) => ({ ...prev, cupturn: event.target.value }))
                    }
                    readOnly={!isCheckedBreaking}
                    breaking={true}
                    placeholder="Enter Cupturn"
                />
              </div>
            </div>
            <div className="create__left_check tabCreate">
              Domains
              <div className="create__left_check_block">
                <Multiselect label="Select domains" value={data.domains || []} options={domainOptions} handleChange={(v) => handleChangeDomains(v)} />
              </div>
            </div>
            <div className="create__left_check tabCreate">
              Tabs
              <div className="create__left_check_block">
                <Dropdown
                    label="Tabs"
                    options={tabsOptions}
                    value={String(data.tab)}
                    handleChange={(value) => setData(p => ({ ...p, tab: Number(value) }))}
                />
              </div>
            </div>
            <div className="create__left_buttons">


              <div
                  onClick={() => history({ pathname: "/admin" })}
                  className="buttons_monitoring"
              >
                Close
              </div>


              <button
                  onClick={() => handleClick(data.domains || [], adminEditNews?.id)}
                  disabled={isFetching}
                  className="buttons_create"
              >
                Publish
              </button>


              <button
                  disabled={isFetching}
                  onClick={() => handleClick(null, adminEditNews?.id)}
                  className="buttons_monitoring buttons_cancle"
              >
                save changes & close
              </button>



            </div>
          </div>
        </div>
      </>
  );
};
