import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../UI/Button";
import { LargePostComponent } from "../components/LargePostComponent";
import { MediumPostComponent } from "../components/MediumPostComponent";
import { SmallPostComponent } from "../components/SmallPostComponent";
import axios from "axios";
import { Link } from "react-router-dom";
import { Loader } from "../UI/Loader";

const StyledWrapper = styled.div`
  display: block;
  margin-bottom: 100px;
`;

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 1000px;
  flex-shrink: 1;
  margin-inline-end: auto;
  padding: 20px;
  margin-bottom: 65px;
  @media screen and (max-width: 600px) {
    padding: 0 43px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: fit-content;
  margin: 0 auto;
`;

interface IProps {
  tab: any;
  isMain:boolean
}

export const PostsListView = ({ tab, isMain }: IProps) => {
  const [posts, setPosts] = useState<any[]>([]);
  const [main, setMain] = useState<any>(undefined);
  const [secodary, setSecondary] = useState<any[]>([]);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getPosts = async () => {
    setIsFetching(true);
    try {
      const res = await axios.get(
          `/api/tab_news/${tab.id}`,
          {
            params: {
              limit: 6,
            },
          }
      );
      if (res) {
        setIsFetching(false);
        setPosts(res.data);
      }
    } catch (e) {
      setIsFetching(false);
    }
  }

  const getMain = async () => {
    axios.get("/api/main/", {

    })
        .then(res => setMain(res.data))
  };

  const getSecondary = async () => {
    axios.get("/api/secondary_main/", {
    })
        .then(res => setSecondary(res.data as any))
  };

  useEffect(() => {
     if(isMain){
       getMain();
       getSecondary();
     }
    getPosts();
  }, [tab]);

  const handleRenderForMain = ()=>{
    return <>
      {main && <LargePostComponent post={main} />}
      {secodary.slice(0, 3).map((el, index)=>(
          <MediumPostComponent key = {index} post={el} />
      ))}
    </>
  }

  const handleRenderForTab = ()=>{
    return <>
      {posts[0] && <LargePostComponent post={posts[0]} />}
      {posts[1] && <MediumPostComponent post={posts[1]} />}


    </>
  }


  return (
    <StyledWrapper>
      <StyledList>
        {isFetching && <Loader />}
        {isMain ? handleRenderForMain() : handleRenderForTab()}
        {posts.map((p, i) => {
          let index = i;
          const borderEnd = index % 2 !== 1;
          const borderBottom = i + 2 < secodary.length;
          return (
              <SmallPostComponent
                  key={p.id}
                  post={p}
                  borderEnd={borderEnd}
                  borderBottom={borderBottom}
              />
          );
        })}

      </StyledList>
      {!!posts.length && (
        <StyledLink to={`/tab/${tab.id}`}>
          <Button>لجميع أحدث المقالات</Button>
        </StyledLink>
      )}
    </StyledWrapper>
  );
};
