import styled from "styled-components";

const StyledButton = styled.button`
  color: #fff;
  font-family: inherit;
  font-size: 24px;
  font-weight: 711;
  padding: 20px 62px;
  background-color: #000;
  border: 2px solid #000;
  border-radius: 500px;
  cursor: pointer;
`;

interface IProps {
  children: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

export const Button = ({ children, onClick, disabled, className }: IProps) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} className={className}>
      {children}
    </StyledButton>
  );
};
