import { Link } from "react-router-dom";
import styled from "styled-components";
import convertUtcToLocal from "../utils/formatDate";

const StyledPost = styled(Link)<{
  $borderEnd: boolean;
  $borderBottom: boolean;
}>`
  position: relative;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 28px 40px;
  margin-bottom: 30px;
  ${({ $borderBottom }) => $borderBottom && "border-bottom: 2px solid #c9c9c9;"}
  width: 50%;
  min-height: 173px;
  &::after {
    ${({ $borderEnd }) => $borderEnd && 'content: "";'}
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 40px;
    width: 2px;
    background-color: #c9c9c9;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom: 2px solid #c9c9c9;
    &:last-child {
      border: none;
    }
    &::after {
      display: none;
    }
  }
`;

const StyledTitle = styled.p`
  font-size: 28px;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 19px;
  }
`;

const StyledDate = styled.p`
  font-size: 18px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 700;
  }
`;

interface IProps {
  post: any;
  borderEnd: boolean;
  borderBottom: boolean;
}

export const SmallPostComponent = ({
  post,
  borderEnd,
  borderBottom,
}: IProps) => {
  return (
    <StyledPost
      to={`/${post.id}`}
      $borderEnd={borderEnd}
      $borderBottom={borderBottom}
    >
      <StyledTitle>{post.title}</StyledTitle>
      <StyledDate>{post?.date && convertUtcToLocal(post?.date)}</StyledDate>
    </StyledPost>
  );
};
