import HeaderLogo from "../assets/images/header-logo.png";
import styled from "styled-components";
import { useState } from "react";
import { TelegramIcon } from "../assets/images/TelegramIcon";
import { WhatsappIcon } from "../assets/images/WhatsappIcon";
import { CONTENT_WIDTH } from "../constants/constants";
import { Link } from "react-router-dom";
import { useGlobalState } from "../store";
import { translations } from "../constants/translations";

const StyledHeader = styled.header`
  position: relative;
  max-width: ${CONTENT_WIDTH}px;
  padding: 28px 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #c8c8c8;
  gap: 15px;
  margin-bottom: 43px;
  @media screen and (max-width: 1000px) {
    padding: 11px 43px;
    margin-bottom: 16px;
  }
`;

const StyledLogo = styled(Link)`
  margin-inline-end: 50px;
  max-width: 273px;
  
`;

const StyledMenu = styled.nav<{ $opened: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 60px;
  @media screen and (max-width: 1000px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    gap: 40px;
    padding: 0 43px 20px;
    z-index: 20;
    border-bottom: 1px solid #c9c9c9;
    ${({ $opened }) => !$opened && "display: none;"}
  }
`;

const StyledMenuBox = styled.div`
  display: flex;
  align-items: center;
  gap: 27px;
  margin-inline-start: auto;
  @media screen and (max-width: 1000px) {
    flex-direction: row-reverse;
    margin: 0;
    gap: 86px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 21px;
  color: #000;
  @media screen and (max-width: 1000px) {
    border-bottom: 1px solid #c9c9c9;
    padding-bottom: 15px;
    width: 100%;
  }
`;

const StyledBurger = styled.button`
  display: none;
  height: 25px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-inline-start: auto;

  span {
    width: 31px;
    height: 5px;
    background-color: #000;
    display: block;
  }
  @media screen and (max-width: 1000px) {
    display: flex;
  }
`;

export const Header = () => {
  const [tabs, setTabs] = useGlobalState("tabs");
  const [opened, setOpened] = useState<boolean>(false);


  return (
    <StyledHeader>
      <StyledLogo to="/">
        <img src={HeaderLogo} alt="Header logo" />
      </StyledLogo>

      <StyledMenu $opened={opened}>
        {tabs.map((t) => {
          return <StyledLink to={`/tab/${t.id}`} key={t.id}>{translations[t.name]}</StyledLink>
        })}
        <StyledMenuBox>
          <TelegramIcon />
          <WhatsappIcon />
        </StyledMenuBox>
      </StyledMenu>

      <StyledBurger onClick={() => setOpened((p) => !p)}>
        <span></span>
        <span></span>
        <span></span>
      </StyledBurger>
    </StyledHeader>
  );
};
