import { createGlobalState } from "react-hooks-global-state";
import { IGlobalState } from "../types/IGlobalState";
import tech4 from "../assets/img/tech4.jpg";
import newsMain from "../assets/img/newsTestMain.jpg";

const initialState: IGlobalState = {
  isLogin: false,
  isLoggedIn: false,
  news: [],
  tabs: [],
  adminEditNews: null,
};

export const { useGlobalState } = createGlobalState(initialState);
