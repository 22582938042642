import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { MediumPostComponent } from "./MediumPostComponent";
import { Loader } from "../UI/Loader";
import {getImgUrl} from "../utils/getImgUrl";
import convertUtcToLocal from "../utils/formatDate";

const StyledPost = styled.a`
  color: #000;
  width: 100%;
  padding: 0 15px;
`;

const StyledImg = styled.img`
  width: 100%;
  max-width: 960px;
  height: 630px;
  margin-bottom: 45px;
  @media screen and (max-width: 550px) {
    height: 400px;
  }
`;

const StyledTitle = styled.h1`
  color: #000;
  font-family: "SF Arabic";
  font-size: 45px;
  margin-bottom: 10px;
`;

const StyledDate = styled.p`
  font-family: "SF Arabic";
  font-size: 18px;
  font-weight: 711;
  margin-bottom: 45px;
`;

const StyledContent = styled.p`
  color: #000;
  max-width: 100%;

  font-family: "SF Arabic";
  font-size: 21px;
  margin-bottom: 120px;
  *{
    text-align: justify;
  }
`;

const StyledSubtitle = styled.h3`
  color: #000;
  font-family: "SF Arabic";
  font-size: 60px;
  font-weight: 711;
  padding-bottom: 15px;
  border-bottom: 2px solid #000;
  margin-bottom: 35px;
`;

const StyledList = styled.div`
  padding: 0 10px;
`;

export const FullPostComponent = () => {
  let params = useParams();
  const postId = params["*"];
  const [post, setPost] = useState<any | null>(null);
  const [related, setRelated] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getPost = async () => {
    try {
      setIsFetching(true);
      const res = await axios.get(`/api/expand/${postId}/`);
      if (res) {
        setIsFetching(false);
        setPost(res.data);
      }
    } catch (e) {
      setIsFetching(false);
    }
  };

  const getRelated = async () => {
    axios
      .get(`/api/likenews/`, {
        params: {
          limit: 5,
        },
      })
      .then((res) => setRelated(res.data));
  };

  useEffect(() => {
    getPost();
    getRelated();
  }, [postId]);
  if(isFetching){
    return <Loader/>
  }
  return (
    <StyledContainer>

      <StyledPost href={post?.link}>
        <StyledImg src={getImgUrl(post?.media_link)} />
        <StyledTitle>{post?.title}</StyledTitle>
        <StyledDate>{post?.date && convertUtcToLocal(post?.date)}</StyledDate>
        <StyledContent dangerouslySetInnerHTML={{ __html: post?.text }} />
        <StyledSubtitle>مقالات ذات صلة</StyledSubtitle>
      </StyledPost>
      <StyledList>
        {related?.map((p) => (
          <MediumPostComponent post={p} key={p.id} />
        ))}
      </StyledList>
    </StyledContainer>
  );
};


const StyledContainer = styled.div`
  width: 100%;
`
