import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react"





interface IProps {
    value?: string[];
    label: string;
    options: { value: string; name: string }[];
    handleChange: (val: string[]) => void;
}






const Multiselect = React.memo(({ label, options, value, handleChange }: IProps) => {
    return <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <InputLabel id="demo-select-small">{label}</InputLabel>
        <Select
            multiple={true}
            labelId="demo-select-small"
            id="demo-select-small"
            value={value}
            label="Tabs"
            onChange={(e) => handleChange(e.target.value as string[])}
        >
            {options.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
            ))}
        </Select>
    </FormControl>
})


export default Multiselect